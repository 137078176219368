<template>
  <footer>
    <div>
      <p>Human Reaction Engine</p>
      <p>&copy; Tanto 2021</p>
    </div>
    <div class="right">
      <router-link to="/privacy-policy">Privacy Policy</router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped lang="scss">
  @import "../style/_variables.scss";
  footer {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 1rem 6rem;
    color: $color-text-light;
  }

  .katana-logo {
    width: 2rem;
  }

  .right {
    display: flex;
    align-items: flex-end;
    text-align: right;
    margin-top: 1rem;
  }

  @media (min-width: 800px) {
    footer {
      justify-content: space-between;
      flex-direction: row;
      padding: 1rem 6rem;
    }

    .right {
      margin-top: 0;
    }
  }
</style>
