<template>
  <div :class="{'hidden': hidden}" class="login-container">
    <div class="screen" @click="hide" />
    <div class="login-content">
      <div class="login" v-if="shown === 'login'">
        <div class="form">
          <h3 class="login-logo">
            <!-- <img src="../assets/mayor wang-icon.png" alt="Mayor Wang logo" /> -->
            Sign in to TOONin
          </h3>
          <div class="login-body">
            <a href="/auth/twitch" class="twitch" @click="login">
              <div>
                <span>Continue with Twitch</span>
                <img src="../assets/TwitchGlitchWhite.svg" alt="Twitch Logo" />
              </div>
            </a>

            <a href="/auth/google" class="google" @click="login">
              <div>
                <span>Continue with YouTube</span>
                <img src="../assets/youtube_social_icon_red.png" alt="Sign in with Google button" />
                <!-- <img src="../assets/google_signin_pressed.png" alt="Sign in with Google button" /> -->
              </div>
            </a>

            <hr />

            <a @click="toggle('signup')" id="btn-signup">Don't have an account?</a>
          </div>
        </div>
      </div>

      <div class="sign-up" v-if="shown === 'signup'">
        <div class="form">
          <h3 class="login-logo">
            <!-- <img src="../assets/mayor wang-icon.png" /> -->
            Sign up with TOONin
          </h3>
          <div class="login-body">
            <p class="error" v-if="loginError">TOONin is currently by request only. Please reach out to us to get access:</p>
            <p v-else>TOONin is currently by request only. Please sign up and we will reach out with your account details.</p>

            <h3 class="margin-bottom-xsmall">Streaming channel:</h3>

            <div class="input-group">
              <div class="input-group_sub">
                <label for="twitchID" :class="{'error': isInvalid(['twitchID', 'youtubeID'])}">Twitch Username</label>
                <input
                  type="text"
                  name="twitchID"
                  v-model="twitchID"
                  placeholder="YourUsername"
                  :class="{'error': isInvalid(['twitchID', 'youtubeID'])}" />
              </div>

              <div class="or">Or</div>

              <div class="input-group_sub">
                <label for="youtubeID" :class="{'error': isInvalid(['twitchID', 'youtubeID'])}">Youtube Channel URL</label>
                <input
                  type="text"
                  name="youtubeID"
                  v-model="youtubeID"
                  placeholder="https://www.youtube.com/channel/your_id"
                  :class="{'error': isInvalid(['twitchID', 'youtubeID'])}" />
                </div>
            </div>

            <h3 class="margin-bottom-xsmall">Contact info:</h3>

            <label for="name" :class="{'error': invalidFields.includes('name')}">Name</label>
            <input
              type="text"
              autocomplete="name"
              name="name"
              v-model="name"
              :class="{'error': invalidFields.includes('name')}" />

            <label for="email" :class="{'error': invalidFields.includes('email')}">Email</label>
            <input
              type="email"
              name="email"
              v-model="email"
              :class="{'error': invalidFields.includes('email')}" />

            <label for="followers" :class="{'error': invalidFields.includes('followers')}">Number of Followers</label>
            <input
              type="number"
              name="followers"
              v-model="followers"
              :class="{'error': invalidFields.includes('followers')}" />

            <div>
            <button
              @click="signup"
              :class="{'disabled': !requiredProperties}"
              class="button"
              >
              <div v-if="sending" class="loader"></div>
              <span v-else>Submit</span>
            </button>
          </div>

            <div v-if="signupSuccess" class="success margin-top-small">
              Thank you! We will reach out to you shortly.
            </div>
            <div v-if="signupError" class="error margin-top-small">
              {{this.signupError}}
            </div>
            <hr />
            <a @click="toggle('login')" id="btn-login">Already have an account?</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      email: null,
      name: null,
      twitchID: null,
      youtubeID: null,
      followers: 0,
      shown: 'login',
      loginError: false,
      signupError: false,
      signupSuccess: false,
      contactEmail: 'mayorwang.katana@gmail.com',
      invalidFields: [],
      sending: false,
      hidden: true,
    };
  },
  computed: {
    requiredProperties() {
      return this.name
          && this.email
          && this.followers
          && (this.twitchID || this.youtubeID);
    },
  },
  watch: {
    $route() {
      this.checkState();
    },
  },
  methods: {
    isInvalid(props) {
      const missing = [];
      props = Array.isArray(props) ? props : [props];
      props.forEach((prop) => {
        if (this.invalidFields.includes(prop)) {
          missing.push(prop);
        }
      });

      return missing.length === props.length;
    },
    validateForm(values) {
      let valid = true;
      Object.keys(values).forEach((key) => {
        let isValid = values[key] && !this.isDangerous(values[key]);
        if (isValid && key === 'email') {
          isValid = this.isEmail(values[key]);
        }

        if (!isValid) {
          this.invalidFields.push(key);
          valid = false;
        }
      });

      return valid;
    },
    isDangerous(val) {
      return val.match(/<[a-zA-Z!/?]|&#/) !== null;
    },
    isEmail(val) {
      return val.match(/\S+@\S+$/) !== null;
    },
    login() {
      gtag('event', 'login');
    },
    signup() {
      if (this.sending) {
        return;
      }

      this.signupSuccess = null;
      this.signupError = null;
      this.sending = true;
      this.invalidFields = [];

      const {
        name, twitchID, email, followers, youtubeID,
      } = this;
      const isValid = this.validateForm({
        name, email, followers,
      });
      const hasLogin = this.validateForm({ twitchID }) || this.validateForm({ youtubeID });

      if (!isValid || !hasLogin) {
        this.sending = false;
        this.signupError = 'Please fill in your contact details above.';
        return;
      }

      const { href } = window.location;
      const promo = (new URL(document.location)).searchParams.get('promo');
      gtag_report_conversion(href);
      gtag('event', 'sign_up', { promo });

      fetch('/signup', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name, twitchID, email, followers, promo, youtubeID,
        }),
      })
        .then((res) => {
          this.sending = false;
          if (res.status !== 200) throw res;
          this.signupSuccess = true;
          this.signupError = false;
        })
        .catch((err) => {
          console.log('Sign up error: ', err);
          this.signupError = `An error has occurred. Please reach out to us at ${this.contactEmail}`;
          this.signupSuccess = false;
        });
    },
    toggle(type) {
      this.reset();
      gtag('event', 'click', { btn: 'login_toggle', value: type });
      if (type === 'login') {
        this.$router.push({ path: '/log-in' });
      } else if (type === 'signup') {
        this.$router.push({ path: '/sign-up' });
      }
    },
    hide() {
      const self = this;
      this.$router.push({ path: '/' });
      self.hidden = true;
      // wait for fade out
      setTimeout(() => {
        self.reset();
        self.shown = 'login';
      }, 200);
    },
    reset() {
      this.loginError = false;
      this.signupError = false;
      this.signupSuccess = false;
      this.sending = false;
      this.invalidFields = [];
      this.email = null;
      this.name = null;
      this.twitchID = null;
      this.followers = null;
      document.querySelector('body').classList.remove('no-scroll');
    },
    checkState() {
      const query = window.location.search;
      const { pathname } = window.location;

      if (pathname === '/sign-up') {
        this.hidden = false;
        this.shown = 'signup';
        document.querySelector('body').classList.add('no-scroll');
      } else if (pathname === '/log-in') {
        this.hidden = false;
        this.shown = 'login';
        document.querySelector('body').classList.add('no-scroll');
      } else {
        this.reset();
      }

      if (query) {
        const params = new URLSearchParams(query);
        const message = params.get('error');

        if (message === 'invalid-user') {
          this.$emit('togglenav', false);
          this.shown = 'signup';
          this.loginError = true;
        }
      }
    },
  },
  beforeMount() {
    this.checkState();
  },
};
</script>

<style scoped lang="scss">
  @import "../style/_variables.scss";

  .login-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999999;
    opacity: 1;
    visibility: visible;
    transition: opacity .2s ease, visibility .2s ease;

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }

    hr {
      border: none;
      border-top: 1px solid $color-grey-light;
      width: 100%;
      margin: 1rem 0;
    }
  }

  .screen {
    background-color: rgba(0, 0, 0, .8);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    cursor: pointer;
    transition: background-color .2s ease;

    &:hover {
      background-color: rgba(0, 0, 0, .9);
    }
  }

  .login-content {
    border: 1px solid grey;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    max-width: 500px;
    padding: 1rem 0;
    background-color: #fff;
    border-radius: 1rem;
    z-index: 1;
    margin: 0 1rem;
    max-height: calc(100vh - 4rem);
    overflow-y: auto;

    h3 {
      margin-top: 0;
    }
  }

  .login-body {
    padding: 1rem 1rem 0;
    display: flex;
    flex-direction: column;

    p {
      margin-top: 0;
    }
  }

  .login-container > *,
  .form,
  .sign-up,
  .sign-up > * {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .form {
    // margin-bottom: 1rem;

    button {
      align-self: flex-start;
    }

    input {
      margin-bottom: 1rem;
    }
  }

  .login {
    text-align: center;
  }

  .login-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $color-grey-light;
    padding-bottom: 1rem;

    img {
      width: auto;
      height: 2rem;
      margin-right: .5rem;
    }
  }

  .twitch, .google {
    display: flex;
    justify-content: center;
    color: $color-white;
    text-decoration: none;

    span {
      line-height: 1rem;
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: .5rem 2rem;
      border-radius: 2rem;
      transition: background-color .2s ease;

      img {
        height: 1.5rem;
        margin-left: .5rem;
      }
    }
  }

  .twitch {
    &:hover div {
      background-color: #772ce8;
    }

    div {
      background-color: #9147FF;
    }
  }

  .google {
    margin-top: 1rem;

    &:hover div {
      background-color: #0e0e0e;
    }

    div { background-color: #292929;}
  }

  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .input-group_sub {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .or {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    margin-bottom: 1rem;

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      width: calc(50% - 2rem);
      height: 1px;
      border-top: 1px solid $color-grey-light;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }
</style>
