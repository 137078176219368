<template>
  <div class="content-shrink">
    <h1 class="margin-top-large margin-bottom-large">Streaming History</h1>

    <div class="history">
      <div class="history-item flex column-12 hide-small">
        <div class="column-9 column row-medium">
          <div class="cell column-12 column-4-medium cell-date">
            Date
          </div>
          <div class="cell column-12 column-4-medium">
            Time
          </div>
          <div class="cell column-12 column-4-medium">
            Chats
          </div>
        </div>
        <div class="column-2 cell service-cell">
          Service
        </div>
        <div class="column-1 cell service-cell">
        </div>
      </div>
      <div v-for="item in history" :key="item.id" class="history-item-link">
        <div class="history-item flex column-12">
          <a :href="`/history/${item.id}`" class="history-item-link column-11">
            <div class="column-10 column row-medium">
              <div class="cell column-12 column-4-medium cell-date">
                {{item.date}}
              </div>
              <div class="cell column-12 column-4-medium">
                {{item.time}}
              </div>
              <div class="cell column-12 column-4-medium">
                {{item.chats}}
              </div>
            </div>
            <div class="column-2 cell service-cell">
              <img v-if="includesProvider(item.type, 'youtube')" class="margin-right-small" src="../assets/youtube_social_icon_red.png" alt="Sign in with Google button" />
              <img v-if="includesProvider(item.type, 'twitch')" src="../assets/TwitchGlitchWhite.svg" alt="Twitch Logo" />
            </div>
          </a>
          <div class="column-a cell service-cell history-item-delete" @click="deleteModal(item.id)">
            <a><svg height="24px" viewBox="0 0 24 24" width="24px" ><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg></a>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="{'hidden': !deleteStreamId}">
      <div class="screen" @click="closePrompt" />
      <div class="modal-content">
        <h3>Are you sure you want to delete this stream?</h3>
        <p>You will no longer be able to see any data from this stream.</p>
        <div class="modal-buttons">
          <button class="button margin-right-small" @click="() => deleteStream(deleteStreamId)">Delete</button>
          <button class="button light" @click="closePrompt">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'StreamAnalysis',
  data() {
    return {
      history: [],
      deleteStreamId: null,
    };
  },
  methods: {
    includesProvider(type, provider) {
      return type === provider || type.includes(provider);
    },
    setHistory(data) {
      this.history = data.map((item) => {
        const start = new Date(item.start_time);
        const end = new Date(item.end_time);
        const startHours = start.getHours();
        const startMinutes = start.getMinutes() ? start.getMinutes() : '00';
        const endHours = end.getHours();
        const endMinutes = end.getMinutes() ? end.getMinutes() : '00';

        const time = `${startHours > 12 ? startHours - 12 : (startHours || 12)}:${startMinutes}${startHours >= 12 ? 'pm' : 'am'} - ${endHours > 12 ? endHours - 12 : (endHours || 12)}:${endMinutes}${endHours >= 12 ? 'pm' : 'am'}`;
        return {
          id: item.id,
          date: `${start.getMonth() + 1}/${start.getDate()}/${start.getFullYear()}`,
          time,
          type: item.service.split(','),
          chats: 0,
        };
      });
    },
    getStreams() {
      return fetch('/streams', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Charset': 'utf-8',
        },
      })
        .then((res) => res.json())
        .then((streams) => {
          if (streams && streams.length) {
            this.setHistory(streams);
            streams.forEach((stream) => this.getChatData(stream.id));
          }
        })
        .catch((error) => {
          console.log('streams error', error);
        });
    },
    getChatData(id) {
      return fetch(`/chat/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Charset': 'utf-8',
        },
      })
        .then((res) => res.json())
        .then((chatData) => {
          if (chatData && chatData.length) {
            const stream = this.history.find((data) => data.id === id);

            if (stream) {
              stream.chats = chatData.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
          }
        })
        .catch((error) => {
          console.log('streams error', error);
        });
    },
    deleteModal(id) {
      this.deleteStreamId = id;
    },
    closePrompt() {
      this.deleteStreamId = null;
    },
    deleteStream(id) {
      console.log('dlete', id);
      return fetch(`/stream/${id}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Charset': 'utf-8',
        },
      })
        .then(() => {
          const index = this.history.findIndex((h) => h.id === id);
          this.history.splice(index, 1);
          this.closePrompt();
        })
        .catch((error) => {
          console.log('stream delete error', error);
          this.closePrompt();
        });
    },
  },
  mounted() {
    this.getStreams();
  },
  beforeDestroy() {
  },
};
</script>

<style scoped lang="scss">
  @import "../style/_variables.scss";

  .content-shrink {
    max-width: 1000px;
    margin: 0 auto;
  }

  .history-item-link a {
    color: $color-text;
    text-decoration: none;
    padding: .5rem 0;
    display: flex;
    transition: background-color .2s ease;

    &:hover {
      background-color: $color-primary-dark;
    }
  }

  .history-item-delete a {
    &:hover {
      background-color: transparent;
    }
  }

  .history-item {
    border-bottom: 1px solid $color-grey-light;
    font-size: 1.3rem;
    align-items: center;

    img {
      height: 1.5rem;
    }
  }

  .cell {
    // align-items: center;
    padding: .5rem 1rem .5rem;
  }

  .cell-date {
    font-weight: $text-bold;
  }

  .service-cell {
    justify-content: flex-end;
  }

  svg {
    fill: $color-primary;
  }

  a:hover svg {
    fill: $color-primary-dark;
  }

  @media only screen and (min-width: 800px) {
    .service-cell {
      justify-content: flex-start;
    }
  }
</style>
