const vader = require('vader-sentiment').SentimentIntensityAnalyzer;
const translate = require('./helpers/translate').default;

const WAIT = 10000;
const MAX_SIZE = 50;
const SLEEP_TIMER = 30;
const DEFAULT_KEYWORDS = {
  yes: {
    keywords: ['yes'],
    sentiment: 'yes',
  },
  no: {
    keywords: ['no'],
    sentiment: 'no',
  },
};
let customKeywords = { ...DEFAULT_KEYWORDS };
let bank = [];
let timer;
let listener;
let sleep = 0;

function clearTimer() {
  if (timer) {
    clearTimeout(timer);
    timer = null;
  }
}

function checkCustomWords(message = '') {
  const keys = Object.keys(customKeywords);
  if (keys.length > 0) {
    const words = message.split(' ').map((word) => word.toLowerCase().replace(/[.!?'"\\-]/g, ''));
    const result = {};

    keys.forEach((key) => {
      const val = customKeywords[key];
      const { keywords, sentiment } = val;
      keywords.forEach((keyword) => {
        if (words.indexOf(keyword.toLowerCase()) > -1) {
          result[key] = result[sentiment] ? result[sentiment]++ : 1;
        }
      });
    });

    const resultKeys = Object.keys(result);
    const maxResult = resultKeys.length > 0 ? resultKeys.reduce((acc, curr) => (result[acc] > result[curr] ? acc : curr)) : null;

    return maxResult ? { compound: customKeywords[maxResult].sentiment } : null;
  }

  return null;
}

function getPolarityScore(message) {
  const result = vader.polarity_scores(message);
  return result;
}

function parseSentimentNoTranslate(message) {
  const customResult = checkCustomWords(message);
  if (customResult) {
    // console.log('Has custom result of: ', customResult);
    return {
      sentiment: customResult,
      originalMessage: message,
      parsedMessage: message,
    };
  }

  const result = getPolarityScore(message);
  return {
    sentiment: result,
    originalMessage: message,
    parsedMessage: message,
  };
}

async function parseSentiment(message) {
  const customResult = checkCustomWords(message);
  if (customResult) {
    // console.log('Has custom result of: ', customResult);
    return {
      sentiment: customResult,
      originalMessage: message,
      parsedMessage: message,
    };
  }

  const result = getPolarityScore(message);
  if (result.compound === 0) {
    const translated = await translate(message);
    const resultTranslated = getPolarityScore(translated);
    return {
      sentiment: resultTranslated,
      originalMessage: message,
      parsedMessage: translated,
    };
  }
  // console.log(`Sentiment analyzed for message: "${message}" value:`, result);
  return {
    sentiment: result,
    originalMessage: message,
    parsedMessage: message,
  };
}

function parseBank() {
  const custom = {};
  let total = 0;

  clearTimer();

  if (bank.length > 0) {
    sleep = 0;
    let amount = 0;

    bank.forEach((acc) => {
      if (isNaN(acc.compound)) {
        custom[acc.compound] = custom[acc.compound] ? custom[acc.compound] + 1 : 1;
      } else {
        amount += acc.compound;
      }
    });

    if (Object.keys(custom).length > 0) {
      const mostCommon = Object.keys(custom).reduce((a, b) => (custom[a] > custom[b] ? a : b));
      if (custom[mostCommon] >= bank.length / 2) {
        total = mostCommon;
      }
    }

    if (!isNaN(total)) {
      total = amount / bank.length;
    }
  } else {
    sleep++;

    if (sleep >= SLEEP_TIMER) {
      total = 'sleep';
    }
  }

  bank = [];

  if (listener) {
    listener(total);
  }

  timer = setTimeout(parseBank, WAIT);

  console.log('Total sentiment: ', total);
  return total;
}

async function addMessage(message, isDonation) {
  const result = await parseSentiment(message);
  if (isDonation) {
    listener(1);
    return result;
  }

  bank.push(result.sentiment);
  if (bank.length > MAX_SIZE) {
    parseBank();
  } else if (!timer) {
    timer = setTimeout(parseBank, WAIT);
  }

  return result;
}

function onSentiment(cb) {
  listener = cb;
}

function setKeywords(keywords) {
  customKeywords = { ...customKeywords, ...keywords };
  return customKeywords;
}

function addKeyword(id, sentiment, keywords) {
  customKeywords[id] = { sentiment, keywords };
  return customKeywords;
}

function removeKeyword(id, sentiment, keywords) {
  if (keywords && keywords.length) {
    customKeywords[id] = { sentiment, keywords };
  } else {
    delete customKeywords[id];
  }

  return customKeywords;
}

function getMessages() {
  return bank;
}

function start() {
  bank = [];
  timer = setTimeout(parseBank, WAIT);
  return { bank, customKeywords };
}

function _reset() {
  bank = [];
  customKeywords = { ...DEFAULT_KEYWORDS };
  sleep = 0;
  clearTimer();
}

module.exports = {
  start,
  addMessage,
  onSentiment,
  parseSentiment,
  parseSentimentNoTranslate,
  setKeywords,
  addKeyword,
  removeKeyword,
  getMessages,
  parseBank,
  _reset,
};
