<template>
  <div class="pie-chart" ref="chart"></div>
</template>

<script>

import * as d3 from 'd3';

// const sampleData = require('./datachats.json');

export default {
  name: 'Pie',
  props: ['chartData'],
  data() {
    return {
    };
  },
  methods: {
    createSVG() {
      /* eslint-disable */
      const data = this.chartData;
      const pie = d3.pie()
    .sort(null)
    .value(d => d.value);
      const arcs = pie(data);

      const width = 1000;
      const height = Math.min(width, 500);

      // const color = d3.scaleOrdinal()
      //   .domain(data.map(d => d.name))
      //   .range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), data.length).reverse())
      const color = (name) => {
        const colors = {
          neutral: '#4bb5f3',
          happy: '#3fce3f',
          laugh: '#ecec3b',
          disgusting: '#ff4141',
        }

        return colors[name] || '#4bb5f3';
      }
      const arc = d3.arc()
          .innerRadius(0)
          .outerRadius(Math.min(width, height) / 2 - 1);
      const radius = Math.min(width, height) / 2 * 0.8;
      const arcLabel = d3.arc().innerRadius(radius).outerRadius(radius);

      const svg = d3.create("svg")
          .attr("viewBox", [-width / 2, -height / 2, width, height]);

      svg.append("g")
          .attr("stroke", "white")
        .selectAll("path")
        .data(arcs)
        .join("path")
          .attr("fill", d => color(d.data.name))
          .attr("d", arc)
        .append("title")
          .text(d => `${d.data.name}: ${d.data.value.toLocaleString()}`);

      svg.append("g")
          .attr("font-family", "sans-serif")
          .attr("font-size", 12)
          .attr("text-anchor", "middle")
        .selectAll("text")
        .data(arcs)
        .join("text")
          .attr("transform", d => `translate(${arcLabel.centroid(d)})`)
          .call(text => text.append("tspan")
              .attr("y", "-0.4em")
              .attr("font-weight", "bold")
              .text(d => d.data.name))
          .call(text => text.filter(d => (d.endAngle - d.startAngle) > 0.25).append("tspan")
              .attr("x", 0)
              .attr("y", "0.7em")
              .attr("fill-opacity", 0.7)
              .text(d => d.data.value.toLocaleString()));

      return svg.node();
      /* eslint-enable */
    },
  },
  mounted() {
    const svg = this.createSVG();
    this.$refs.chart.append(svg);
  },
  beforeDestroy() {
  },
};
</script>

<style scoped lang="scss">
  .pie-chart {
    width: 100%;
  }
</style>
