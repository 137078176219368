<template>
  <div>
    <div :class="{ 'hidden': hidden }" class="toast">
      <h2>{{title}}</h2>
      <p class="toast-body">
        {{body}}
      </p>
      <div v-if="details" class="toast-details">
        <div v-if="detailsShown" class="toast-details_content">
          <hr />
          <div v-html="details" />
          <hr />
        </div>
        <button @click="toggleDetails" class="button-inline toast-details_toggle">
          <span v-if="detailsShown">See less</span>
          <span v-else>See more</span>
        </button>
      </div>
      <div class="margin-top-small">
        <a
          class="button"
          :href="'/sign-up?promo=' + this.type"
          @click="() => gtag('event', 'click', { btn: 'promo-sign-up'})"
          >Sign up now</a>
      </div>
      <button class="button-close" @click="hideToast" />
    </div>
  </div>
</template>

<script>
const promos = require('../content/promos.json');

export default {
  name: 'Promo',
  props: ['type'],
  data() {
    const data = promos[this.type];
    return {
      hidden: true,
      title: data ? data.title : null,
      body: data ? data.body : null,
      details: data ? data.details : null,
      detailsShown: false,
    };
  },
  methods: {
    hideToast() {
      this.hidden = true;
    },
    toggleDetails() {
      this.detailsShown = !this.detailsShown;
      gtag('event', 'togglePromoDetails', { value: this.detailsShown });
    },
  },
  mounted() {
    const self = this;
    if (this.title && this.body) {
      setTimeout(() => {
        self.hidden = false;
      }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
  h2 {
    margin-top: 0;
  }

  .toast-details_content {
    hr:first-of-type {
      margin-bottom: .5rem;
    }
  }
</style>
