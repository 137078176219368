<template>
 <div class="margin-top-large readable full-flex background-dark">
  <div v-if="!loaded" class="content-loader-container">
    <div class="loader" />
    <h3 class="margin-top-medium">Parsing data...</h3>
  </div>

  <div v-else-if="loaded && streamInfo">
    <div class="margin-bottom-large">
      <h1>Sentiment analysis</h1>
      <h3 v-if="streamInfo">{{streamInfo.readable}}</h3>
    </div>

    <div class="margin-bottom-large flex" v-if="streamInfo">
      <div class="column-3 column">
        <div v-if="streamInfo.length"><Stat title="Stream Length" :data="streamInfo.length" /></div>
        <div><Stat title="Total Chats" :data="totalChats" /></div>
        <div><Stat title="Unique participants" :data="uniqueUsers" /></div>
      </div>
      <div class="column-9" v-if="chatPieData">
        <Pie :chartData="chatPieData" />
      </div>
    </div>
  </div>

  <div v-if="loaded && !error">
    <div class="margin-bottom-large flex" v-if="streamData">
      <h2>Displayed reactions</h2>
      <div class="column-9">
        <Pie :chartData="getPieSentimentData()" />
      </div>
    </div>

    <div class="margin-bottom-large" v-if="chatData">
      <h2>Chat sentiment by percent</h2>
      <Stacked :chartData="getChats()" key="chat-sentiment-stacked" />
    </div>

    <div class="margin-bottom-large" v-if="chatData">
      <h2>Chat sentiment by reaction</h2>
      <Stacked :chartData="getChatSentiment()" key="chat-sentiment-stacked" />
    </div>

    <div class="margin-bottom-large" v-if="streamData">
      <h2>Reaction stacked</h2>
      <Stacked :chartData="getSentiments()" key="reaction-stacked" />
    </div>

    <div class="margin-bottom-large" v-if="streamData">
      <h2>Reaction heatmap-ish</h2>
      <Stacked :chartData="getSentimentHeatmap()" key="heatmap" />
    </div>

    <div class="margin-bottom-large" v-if="streamInfo && topUser">
      <h2>Top user journey</h2>
      <h3 class="margin-bottom-medium">{{topUser.name}}</h3>

      <div class="margin-bottom-large flex">
        <div class="column-3 column">
          <div><Stat title="Total Chats" :data="userChats(topUser).length" /></div>
        </div>
        <div class="column-9">
          <Pie :chartData="userPie(topUser)" />
        </div>
      </div>

      <LineChart :chartData="userSentiment(topUser)" />
    </div>

    <div class="margin-bottom-large" v-if="sortedChats && showChatBreakdown()">
      <h2>Chat breakdown</h2>

      <div class="margin-bottom-large flex">
        <div class="column-8">
          <Sunburst :chartData="sortedChats" />
        </div>
      </div>
    </div>

    <div class="margin-bottom-large" v-if="sortedChats && uncommonWords.length > 0">
      <h2>Uncommon common neutral words</h2>
      <div class="margin-bottom-large flex">
        <div class="column-8">
          <Histogram :chartData="uncommonWords" />
        </div>
      </div>
    </div>
  </div>

  <div v-if="loaded && error">
    <h2 v-if="chatData && chatData.length <= 0">Looks like your stream didn't have any chat participation :(</h2>
    <h2 v-else>An error has occurred, please try again later.</h2>

    <button class="button margin-top-small" @click="goBack">Return</button>
  </div>
 </div>
</template>

<script>
import { lexicon } from 'vader-sentiment/src/vader_lexicon';
import Stacked from '../components/charts/Stacked.vue';
import Pie from '../components/charts/Pie.vue';
import Histogram from '../components/charts/Histogram.vue';
import LineChart from '../components/charts/LineChart.vue';
import Stat from '../components/charts/Stat.vue';
import Sunburst from '../components/charts/Sunburst.vue';

import { parseSentimentNoTranslate } from '../hre';

const videos = require('../content/videos.json');

const months = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];

export default {
  name: 'StreamAnalysis',
  data() {
    return {
      streamData: null,
      chatData: null,
      streamInfo: null,
      totalChats: 0,
      uniqueUsers: 0,
      sortedUsers: null,
      sortedChats: null,
      chatPieData: null,
      topUser: null,
      loaded: false,
      error: false,
    };
  },
  components: {
    Stacked,
    Pie,
    Histogram,
    LineChart,
    Stat,
    Sunburst,
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    async parseChatData(data) {
      this.sortedChats = await this.chatHierarchy(data);
      // this.chatPieData = this.sortedChats.children.map((child) => ({
      //   name: child.name,
      //   value: child.children.length,
      // }));
      this.chatPieData = this.getChatPieData();
      this.totalChats = this.getChatAmount();
      this.uniqueUsers = this.getUsers();
      this.sortedUsers = this.sortUsers();
      this.topUser = this.getTopUser();
      this.uncommonWords = this.getUncommonWords();
      this.loaded = true;
    },
    showChatBreakdown() {
      let show = false;
      this.sortedChats.children.find((group) => {
        if (group.children.length > 0) {
          show = true;
          return true;
        }

        return false;
      });

      return show;
    },
    getRepresentation(sentiment) {
      return videos.reduce((prev, curr) => {
        const val = prev.sentiment;
        return (Math.abs(curr.sentiment - sentiment) < Math.abs(val - sentiment) ? curr : prev);
      });
    },
    getPieSentimentData() {
      /* eslint-disable */
      let total = {};
      this.streamData.map((d) => {
        if (!total[d.value]) total[d.value] = 0;
        total[d.value]++;
      });

      return Object.keys(total).map((d) => {
        return {
          name: d,
          value: total[d],
        };
      });
      /* eslint-enable */
    },
    getChatPieData() {
      const data = this.chatData;
      const total = {};
      data.forEach((d) => {
        const value = this.getRepresentation(d.sentiment.compound).id;
        if (total[value]) {
          total[value]++;
        } else {
          total[value] = 1;
        }
      });

      return Object.keys(total).map((key) => ({
        name: key,
        value: total[key],
      }));
    },
    getChats() {
      /* eslint-disable */
      let parsed = this.chatData.map(d => {
        const value = d.sentiment.compound;
        return {
          time: new Date(d.time),
          negative: value < 0 ? 1 : 0,
          neutral: value < 0.05 && value >= 0 ? 1 : 0,
          positive: value >= 0.05 ? 1 : 0,
        };
      });

      var perChunk = parsed.length < 50 ? 1 : 50 // items per chunk

      var result = parsed.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/perChunk)

        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, []);

      const mapped = result.map((d) => {
        let total = {};
        d.map((arr) => {
          if (Object.keys(total).length === 0) {
            total = arr;
          } else {
            total.negative += arr.negative;
            total.neutral += arr.neutral;
            total.positive += arr.positive;
          }
        });
        return total;
      })

      const data = Object.assign(mapped, { y: 'Number of chats' });
      return data;
      /* eslint-enable */
    },
    getChatSentiment() {
      /* eslint-disable */
      let parsed = this.chatData.map((data) => {
        const time = data.time;
        data.date = new Date(time);
        data.value = this.getRepresentation(data.sentiment.compound).id;
        return data;
      });

      var perChunk = parsed.length < 50 ? 1 : 50 // items per chunk    

      var result = parsed.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/perChunk)

        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, []);

      const mapped = result.map((d) => {
        let total = {
          time: 0,
        };

        videos.forEach((v) => {
          total[v.id] = 0;
        });

        d.map((arr) => {
          if (!total.time) total.time = arr.date;
          total[arr.value]++;
        });
        return total;
      });

      const data = Object.assign(mapped, { y: 'Number of chats' });
      return data;
      /* eslint-enable */
    },
    getSentiments() {
      /* eslint-disable */
      let parsed = this.streamData;

      var perChunk = parsed.length < 10 ? 1 : 10 // items per chunk    

      var result = parsed.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/perChunk)

        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, []);

      const mapped = result.map((d) => {
        let total = {
          time: 0,
        };

        videos.forEach((v) => {
          total[v.id] = 0;
        });

        d.map((arr) => {
          if (!total.time) total.time = arr.time;
          total[arr.value]++;
        });
        return total;
      });

      const data = Object.assign(mapped, { y: 'Number of chats' });
      return data;
      /* eslint-enable */
    },
    getSentimentHeatmap() {
      /* eslint-disable */
      let parsed = this.streamData;
      const normalized = parsed.map((d) => {
        let total = {
          time: d.time,
        };

        videos.forEach((v) => {
          total[v.id] = 0;
        });

        total[d.value]++;
        return total;
      })


      const data = Object.assign(normalized, { y: 'Number of chats' });
      return data;
      /* eslint-enable */
    },
    getChatAmount() {
      return this.chatData.length;
    },
    getUsers() {
      const users = this.chatData.map((chat) => chat.name);
      return [...new Set(users)].length;
    },
    sortUsers() {
      const users = [];
      this.chatData.forEach((chat) => {
        const i = users.findIndex((u) => u.name === chat.name);
        if (i > -1) {
          users[i].total++;
        } else {
          users.push({
            name: chat.name,
            total: 1,
          });
        }
      });

      users.sort((a, b) => {
        if (a.total < b.total) {
          return 1;
        }
        if (a.total > b.total) {
          return -1;
        }
        return 0;
      });

      return users;
    },
    getTopUser() {
      return this.sortedUsers ? this.sortedUsers[0] : null;
    },
    userChats(user) {
      const data = this.chatData;
      if (user) {
        const filtered = data.filter((d) => d.name === user.name);
        return filtered.map((d) => {
          const value = d.sentiment.compound;
          d.value = this.getRepresentation(value).id;
          d.date = new Date(d.time);
          return d;
        });
      }

      return [];
    },
    userSentiment(user) {
      /* eslint-disable */
      const data = this.userChats(user);
      const start = { time: this.streamInfo.start, compound: 0 };
      const end = { time: this.streamInfo.end, compound: 0 };

      if (data) {
        data.unshift(start);
        data.push(end);
        const filled = [];
        data.forEach((d, i) => {
          const last = filled[i - 1];
          if (last && last.date && (d.date - last.date >= 180000)) {
            filled.push({
              date: new Date(d.date - 1),
            });
          }

          filled.push(d);
        });
        
        return Object.assign(filled, { y: 'Sentiment' });
      }

      return [];
      /* eslint-enable */
    },
    userPie(user) {
      /* eslint-disable */
      const data = this.userChats(user);
      const total = {
        laugh: 0,
      };
      data.map((d) => {
        if (!total[d.value]) total[d.value] = 0;
        total[d.value]++;
      });

      return Object.keys(total).map((d) => {
        return {
          name: d,
          value: total[d],
        };
      });
      /* eslint-enable */
    },
    async chatHierarchy(chats) {
      const groups = {};
      chats.forEach((chat) => {
        const sentiment = this.getRepresentation(chat.sentiment.compound).id;
        if (!groups[sentiment]) {
          groups[sentiment] = {
            name: sentiment,
            children: [],
          };
        }

        groups[sentiment].children.push(chat);
      });

      const averaged = {};
      for (const key of Object.keys(groups)) {
        const group = groups[key];
        const { children, name } = group;
        const allWords = [];

        for (const child of children) {
          const { msg } = child;
          const words = msg.toString().toLowerCase().split(' ');

          for (const word of words) {
            const val = await parseSentimentNoTranslate(word);
            const sentiment = this.getRepresentation(val.compound).id;
            const isUncommon = !lexicon[word];
            if (sentiment === name) {
              const i = allWords.findIndex((w) => w.name === word);
              if (i < 0) {
                allWords.push({
                  name: word,
                  value: 1,
                  isUncommon,
                });
              } else {
                allWords[i].value++;
              }
            }
          }
        }

        averaged[name] = {
          name,
          children: allWords
            .filter((word) => word.value > 20)
            .sort((a, b) => {
              if (a.value < b.value) {
                return 1;
              }
              if (a.value > b.value) {
                return -1;
              }
              return 0;
            }).slice(0, 50),
        };
      }

      const data = {
        name: 'Chat',
        children: Object.keys(averaged).map((key) => averaged[key]),
      };

      return data;
    },
    getUncommonWords() {
      const data = this.sortedChats || {};
      const neutral = data.children.find((c) => c.name === 'neutral');
      return neutral ? neutral
        .children
        .filter((chat) => chat.isUncommon && chat.value > 20)
        .slice(0, 20) : [];
    },
    getStream() {
      const { id } = this.$route.params;
      return fetch(`/stream/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Charset': 'utf-8',
        },
      })
        .then((res) => res.json())
        .then((stream) => {
          if (stream && stream.id) {
            const streamData = {
              start: stream.start_time ? new Date(stream.start_time) : 0,
              end: stream.end_time ? new Date(stream.end_time) : 0,
            };
            const startHours = streamData.start ? streamData.start.getHours() : 0;
            const endHours = streamData.end ? streamData.end.getHours() : 0;
            const startMin = streamData.start ? streamData.start.getMinutes() : 0;
            const endMin = streamData.end ? streamData.end.getMinutes() : 0;
            let time = startHours || startMin ? `${startHours > 12 ? startHours - 12 : (startHours || 12)}:${startMin}${startHours >= 12 ? 'pm' : 'am'}` : '';
            time += endHours || endMin ? `- ${endHours > 12 ? endHours - 12 : (endHours || 12)}:${endMin}${endHours >= 12 ? 'pm' : 'am'}` : '';

            const length = (streamData.end - streamData.start) / 60000;
            const hours = Math.floor(length / 60);
            const minutes = Math.floor(length % 60);

            if (streamData.end) {
              streamData.length = hours
                ? `${hours}h ${minutes}min`
                : `${minutes}min`;
            } else {
              streamData.length = 0;
            }

            streamData.readable = `${months[streamData.start.getMonth()]} ${streamData.start.getDate()}, ${streamData.start.getFullYear()} ${time}`;

            this.streamInfo = streamData;
            try {
              this.streamData = stream.sentiments.map((sentiment) => JSON.parse(sentiment));
            } catch (err) {
              console.log('parsing error', err);
            }
          }
        })
        .then(this.getChatData)
        .catch((error) => {
          console.log('streams error', error);
          this.error = true;
          this.loaded = true;
        });
    },
    getChatData() {
      const { id } = this.$route.params;
      return fetch(`/chat/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Charset': 'utf-8',
        },
      })
        .then((res) => res.json())
        .then((chatData) => {
          if (chatData && chatData.length) {
            const parsed = chatData.map((chat) => {
              chat.sentiment = JSON.parse(chat.sentiment);
              return chat;
            });

            this.chatData = parsed;
            this.parseChatData(parsed);
          } else {
            this.chatData = [];
            throw Error('no chats');
          }
        });
    },
  },
  mounted() {
    this.getStream();
  },
  beforeDestroy() {
  },
};
</script>

<style scoped lang="scss">
</style>
