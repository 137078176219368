<template>
  <div class="histogram-chart" ref="chart" />
</template>

<script>

import * as d3 from 'd3';

export default {
  name: 'Histogram',
  props: ['chartData'],
  data() {
    return {
    };
  },
  methods: {
    createSVG() {
      /* eslint-disable */
      // const data = this.chartData;
      const map = {
        positive: 1,
        neutral: 0,
        negative: -1,
      }
      const data = this.chartData;
      const width = 1000;
      const height = 500;
      const color = '#4bb5f3'

      const margin = ({top: 20, right: 0, bottom: 30, left: 40});

      const x = d3.scaleBand()
        .domain(data.map(d => d.name))
        .range([margin.left, width - margin.right])
        .padding(0.1);
      const y = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.value)]).nice()
        .range([height - margin.bottom, margin.top]);
      const xAxis = g => g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).tickSizeOuter(0));
      const yAxis = g => g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        .call(g => g.select(".domain").remove());
      const svg = d3.create("svg")
      .attr("viewBox", [0, 0, width, height])
      // .call(zoom);

      svg.append("g")
          .attr("class", "bars")
          // .attr("fill", color)
        .selectAll("rect")
        .data(data)
        .join("rect")
          .attr("x", d => x(d.name))
          .attr("y", d => y(d.value))
          .attr("fill", color)
          .attr("height", d => y(0) - y(d.value))
          .attr("width", x.bandwidth());

      svg.append("g")
          .attr("class", "x-axis")
          .call(xAxis);

      svg.append("g")
          .attr("class", "y-axis")
          .call(yAxis);

      return svg.node();
      /* eslint-enable */
    },
  },
  mounted() {
    const svg = this.createSVG();
    this.$refs.chart.append(svg);
  },
  beforeDestroy() {
  },
};
</script>

<style scoped lang="scss">
  .histogram-chart {
    width: 100%;
  }
</style>
