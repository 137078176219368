import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import Settings from '../views/Settings.vue';
import History from '../views/History.vue';
import StreamAnalysis from '../views/StreamAnalysis.vue';

const DEFAULT_TITLE = 'TOONin';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/promotion/:id',
    name: 'Promo',
    component: Home,
    props: { promo: 'pilot' },
  },
  {
    path: '/setup',
    name: 'Setup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "setup" */ '../views/Setup.vue'),
    meta: { title: 'Setup' },
  },
  {
    path: '/stream',
    name: 'Stream',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "setup" */ '../views/Stream.vue'),
    meta: { title: 'Stream' },
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: Home,
    meta: { title: 'Sign up' },
  },
  {
    path: '/log-in',
    name: 'Login',
    component: Home,
    meta: { title: 'Sign in' },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    // component: import(/* webpackChunkName: "privacyPolicy" */ '../views/PrivacyPolicy.vue'),
    component: PrivacyPolicy,
    meta: { title: 'Privacy Policy' },
  },
  {
    path: '/settings',
    name: 'Settings',
    // component: import(/* webpackChunkName: "privacyPolicy" */ '../views/PrivacyPolicy.vue'),
    component: Settings,
    meta: { title: 'Settings' },
  },
  {
    path: '/history',
    name: 'History',
    // component: import(/* webpackChunkName: "privacyPolicy" */ '../views/PrivacyPolicy.vue'),
    component: History,
    meta: { title: 'History' },
  },
  {
    path: '/history/:id',
    name: 'StreamAnalysis',
    // component: import(/* webpackChunkName: "privacyPolicy" */ '../views/PrivacyPolicy.vue'),
    component: StreamAnalysis,
    meta: { title: 'StreamAnalysis' },
  },
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '/',
  routes,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    const { title } = to.meta;
    document.title = title ? `${DEFAULT_TITLE} - ${title}` : DEFAULT_TITLE;
    window.scrollTo(0, 0);
  });
});

export default router;
