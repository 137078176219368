<template>
  <div id="app" :class="{'hide-nav': hideAll}">
    <Nav />
    <div class="content gutters">
      <router-view/>
    </div>
    <Footer />
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      hideAll: false,
    };
  },
  methods: {
    getURL() {
      const path = window.location.pathname;
      this.hideAll = path === '/stream';
    },
  },
  beforeMount() {
    this.getURL();
  },
};
</script>

<style lang="scss">
  @import "./style/base.scss";
</style>
