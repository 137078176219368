<template>
  <div class="home">
    <!-- <p class="intro readable">Cats are the traditional representatives of the internet, so why not let our cat represent your chat? Our flashy feline Mayor will gauge the reaction of your chat and react accordingly in real-time. You can overlay Mayor Wang using your existing Open Broadcast Software.</p> -->

    <div class="hero">
      <div class="shadow center padding-top-large padding-bottom-large">
        <h1 class="hero-title">Do you understand the reactions of your crowd while you are live?</h1>
        <h2 class="margin-bottom-medium">Stop guessing</h2>
        <h3 class="readable hero-body">We help you understand how your audience is feeling. Every second of it!</h3>
        <button class="button button-primary margin-top-medium" @click="launchSignUp">
          Sign up
        </button>
      </div>
    </div>

    <div class="background-secondary padding-top-xlarge">
      <div class="flex column row-medium">
        <div class="column-12 column-6-medium column margin-bottom-large gutters-small-medium">
          <div class="center">
            <h2 class="margin-bottom-small">Analytics</h2>
            <img class="fit-image" src="../assets/graph-stacked.png" alt="Analytics image" />
            <p class="margin-top-small margin-bottom-small">Instantly collects sentiment data from the words they type in the chat and the on screen emoji feedback buttons they press to be aggregated and displayed in easy to understand charts</p>
            <a href="#analytics" class="button button-primary">Learn more about analytics</a>
          </div>
        </div>

        <div class="column-12 column-6-medium column gutters-small-medium">
          <div class="center">
            <h2 class="margin-bottom-small">Real Time Avatar</h2>
            <img class="fit-image" src="../assets/character-stream.png" alt="Twitch stream utilizing character representation" />
            <p class="margin-top-small margin-bottom-small">TOONin combines this sentiment data from your live stream in real time to render an avatar whose animations are based on the overall emotional reaction</p>
            <a href="#character" class="button button-primary">Learn more about avatars</a>
          </div>
        </div>
      </div>
    </div>

    <div>
      <a name="analytics" />
      <h2 class="padding-top-large margin-bottom-small">Analytics</h2>
      <p class="readable margin-bottom-medium">TOONin is currently supported on YouTube and Twitch where it instantly collects viewer live chat content, words, emojis, immediately converting this into an emotional feedback measurement like a Net Promotor Score (NPS). However, where NPS provides only a single measurement per experience, TOONin records and overlays real-time metrics for every minute of your livestream content. Check out our example analytics visuals to learn more!</p>
      <div class="image-grid">
        <iframe class="video" src="https://www.youtube.com/embed/N3FRS-ysC8w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>

    <div>
      <a name="character" />
      <h2 class="padding-top-large margin-bottom-small">Real Time Avatar</h2>
      <p class="readable margin-bottom-medium">
        The TOONin back end provides real time reaction sentiment representation via animated avatars. The avatar is driven by the content, keywords and emotional context of your audience chat. TOONin gathers data during live streams to create patterns that tie specific audience reactions to on screen click and ad response behavior.

      </p>
      <div class="flex column row-medium">
        <div class="column-12 column-6-medium margin-bottom-medium" v-for="(c, index) in Math.round(cards.length / 2)" :key="index">
          <div v-for="card in cards.slice(index > 0 ? index + 1 : index, index > 0 ? index + 3 : index + 2)" class="card invert column-6 column" :key="card.title">
            <div class="card-image">
              <img :src="card.image.src" :alt="card.image.alt" />
            </div>
            <div class="card-content">
              <h3 class="card-title">{{card.title}}</h3>
              <div class="card-body">{{card.body}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sign-up-callout invert">
      <h2 class="sign-up-callout_title">Interested in streaming with TOONin?</h2>
      <button class="button light" @click="launchSignUp">
        Sign up
      </button>
    </div>

    <Promo :type="promo" v-if="promo" />

    <!-- <div>
      <div class="chat-container">
        <div class="chat">
          <FakeChat />
          <p>Don't be shy, give Mayor Wang a try. See our cat's reaction change when you type 'lol' vs. 'bored' or 'disgusting!'</p>
          <p>Mayor Wang knows that every chat community constituency is unique and that no one knows the language of your fans better than you. You can customize the keywords that Mayor Wang looks out for to gauge your audience reaction. Whether you audience is filled with Boomers, Zoomers, Doomers or Bloomers, Mayor Wang knows how to represent everyone. </p>
        </div>
        <div class="gutters readable intro-content">
          <p class="intro margin-bottom-medium">Cats are the traditional representatives of the internet, so why not let our cat represent your chat? Our flashy feline Mayor will gauge the reaction of your chat and react accordingly in real-time. You can overlay Mayor Wang using your existing Open Broadcast Software.</p>
          <p class="margin-bottom-medium">Elect Mayor Wang to represent your chat and benefit from easy-to-read visual cues. Taking a poll on what game to play next? Tired of trying to quickly count whether the chat is pressing more 1s than 2s? Mayor Wang will let you know whether the chat’s answer is ‘yes’ or ‘no’ by nodding or shaking, respectively.</p>
          <p class="margin-bottom-medium">Digital avatars for content creators are all the rage right now, but what about the audience? Give your supporters a real presence in your livestream content. Mayor Wang’s emotive responses are based on the overall reaction of your chat, regardless of the audience size. </p>
        </div>
      </div>

      <h2 class="margin-top-medium">Why Mayor Wang?</h2>
    </div> -->
  </div>
</template>

<script>
// import FakeChat from '@/components/FakeChat.vue';
import Promo from '@/components/Promo.vue';

export default {
  name: 'Home',
  components: {
    // FakeChat,
    Promo,
  },
  props: {
    promo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cards: [
        {
          title: 'Real time feedback',
          body: 'Chat sentiment at a glance instead of trying to parse quick moving chat.',
          image: {
            src: require('../assets/chat.png'),
            alt: 'Chat feed icon',
          },
        },
        {
          title: 'Higher engagement',
          body: 'Representation from the avatar will encourage viewers to interact with the chat to see the changes.',
          image: {
            src: require('../assets/emojis.jpg'),
            alt: 'Emojis',
          },
        },
        {
          title: 'Customizable',
          body: 'Ability to tailor avatar reactions based on your unique stream culture.',
          image: {
            src: require('../assets/gear.jpg'),
            alt: 'Gear icon',
          },
        },
        {
          title: 'Why Mayor Wang',
          body: 'Have you seen him?',
          image: {
            src: require('../assets/the_myth.jpg'),
            alt: 'Mayor Wang cat character',
          },
        },
      ],
    };
  },
  methods: {
    launchSignUp() {
      gtag('event', 'click', { btn: 'homepage_signup' });
      this.$router.push({ path: '/sign-up' });
    },
  },
};

</script>

<style scoped lang="scss">
  @import "../style/_variables.scss";

  .home {
    // max-width: 1000px;
    margin: 0 auto;
  }

  .chat-container {
    display: flex;
  }

  .chat {
    width: 60%;
  }

  .hero {
    color: $color-white;
    margin-left: -2rem;
    margin-right: -2rem;
    background: url('../assets/graph.png') no-repeat center;
    background-size: cover;
  }

  .hero-title {
    font-size: 4rem;
    max-width: 1000px;
    margin: 2rem auto 1rem;
  }

  .hero-body {
    margin: 0 auto;
  }

  .background-secondary {
    background-color: $color-secondary;
    margin-left: -2rem;
    margin-right: -2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: $color-white;
  }

  .shadow {
    background-color: rgba(0, 0, 0, .6);
  }

  .intro-content {
    width: 40%;
  }

  .intro {
    font-size: 1.3rem;
    line-height: 1.5;
    padding-bottom: 2rem;
    border-bottom: 1px solid $color-primary;
  }

  .readable {
    max-width: 700px;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 2rem;
  }

  .card {
    overflow: hidden;
    // border-radius: 2rem;
    margin: 0 1rem;
    // box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
  }

  .card-image {
    display: flex;
    align-items: flex-start;
    // border-radius: 2rem 2rem 0 0;

    img {
      display: flex;
      width: 100%;
      height: auto;
    }
  }

  .card-content {
    background-color: $color-grey-light;
    padding: 1rem;
    // border-radius: 0 0 2rem 2rem;
    // box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
  }

  .card-body {
    margin: .5rem 0;
  }

  .sign-up-callout {
    background-color: $color-primary;
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 2rem 1rem;
    margin-top: 4rem;
    text-align: center;

    button {
      font-size: 1.5rem;
    }
  }

  .sign-up-callout_title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .image-grid {
    display: flex;
    flex-wrap: wrap;
  }

  .image-grid-item {
    display: flex;
    flex: 1;

    img {
      flex: 1;
    }
  }

  .video {
    width: 100%;
    height: 53vw;
    max-height: 90vh;
  }

  @media (max-width: 1000px) {
    .chat-container {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
    .chat, .intro-content {
      width: 100%;
    }

    .intro-content {
      padding: 0;
    }

    .sign-up-callout {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }
</style>
