<template>
  <div class="stacked-chart" ref="chart"></div>
</template>

<script>

import * as d3 from 'd3';

// const sampleData = require('./datachats.json');

export default {
  name: 'Stacked',
  props: ['chartData'],
  data() {
    return {
    };
  },
  methods: {
    createSVG() {
      /* eslint-disable */
      const data = this.chartData;
      const keys = Object.keys(data[0]).slice(1);
      // let color = d3.scaleOrdinal()
      //   .domain(keys)
      //   .range(d3.schemeCategory10);

      const color = (name) => {
        const colors = {
          neutral: '#4bb5f3',
          positive: '#4bb5f3',
          happy: '#3fce3f',
          positive: '#3fce3f',
          laugh: '#ecec3b',
          disgusting: '#ff4141',
          negative: '#ff4141',
        }

        return colors[name] || '#4bb5f3';
      }
      const width = 1000;
      const height = 500;
      const margin = ({
        top: 20, right: 30, bottom: 30, left: 40,
      });
      const series = d3.stack()
        .keys(keys)
        (data);
      const x = d3.scaleTime()
        .domain(d3.extent(data, d => d.time))
        .range([margin.left, width - margin.right])
      const y = d3.scaleLinear()
        .domain([0, d3.max(series, d => d3.max(d, d => d[1]))]).nice()
        .range([height - margin.bottom, margin.top])
      const area = d3.area()
        .x((d) => x(d.data.time))
        .y0((d) => y(d[0]))
        .y1((d) => y(d[1]));
      const xAxis = (g) => g
        .attr('transform', `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0));
      const yAxis = g => g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        .call(g => g.select(".domain").remove())
        .call(g => g.select(".tick:last-of-type text").clone()
            .attr("x", 3)
            .attr("text-anchor", "start")
            .attr("font-weight", "bold")
            .text(data.y))
      const svg = d3.create('svg')
        .attr('viewBox', [0, 0, width, height]);

      svg.append("g")
        .selectAll("path")
        .data(series)
        .join("path")
          .attr("fill", ({key}) => color(key))
          .attr("d", area)
        .append("title")
          .text(({key}) => key);

      svg.append('g')
        .call(xAxis);

      svg.append('g')
        .call(yAxis);

      return svg.node();
      /* eslint-enable */
    },
  },
  mounted() {
    const svg = this.createSVG();
    this.$refs.chart.append(svg);
  },
  beforeDestroy() {
  },
};
</script>

<style scoped lang="scss">
</style>
