<template>
  <div>
    <h1 class="margin-top-large
    ">Settings</h1>

    <div>
      <h3 class="margin-bottom-small margin-top-medium">Contact info</h3>
      <div class="settings-card">
        <div class="settings-card_input">
          <label
            for="name"
            :class="{'error': invalidFields.includes('name')}">
            Name</label>
          <input
            name="name"
            type="text"
            v-model="user.name"
            :disabled="loading"
            :class="{'error': invalidFields.includes('name')}" />
        </div>
        <hr />
        <div class="settings-card_input">
          <label
            for="email"
            :class="{'error': invalidFields.includes('email')}">
            Email</label>
          <input
            name="email"
            type="email"
            v-model="user.email"
            :disabled="loading"
            :class="{'error': invalidFields.includes('email')}"/>
        </div>
        <hr />
        <div>
          <div class="success margin-bottom-small" v-if="updated">Contact info successfully updated!</div>
          <div v-if="invalidFields.length > 0" class="error margin-bottom-small">
            Please enter your contact information.
          </div>
          <div v-if="updateError" class="error margin-bottom-small">
            An error has occurred, please try again later.
          </div>
          <button
            @click="update"
            :class="{disabled: loading}"
            class="button">
              <div v-if="sending" class="loader" />
              <span v-else>Update</span>
            </button>
        </div>
      </div>

      <h3 class="margin-bottom-small margin-top-medium">Streaming accounts</h3>
      <div class="settings-card">
        <div class="settings-card_input">
          <label for="name">Twitch</label>
          <input
            name="name"
            type="text"
            v-model="user.twitch_name"
            :disabled="true" />
            <button v-if="user.twitch_name" @click="() => promptRemoveAccount('twitch')" class="button error">Remove</button>
            <button v-else @click="() => handleAddAccount('twitch')" class="button">Add</button>
        </div>
        <hr />
        <div class="settings-card_input">
          <label for="name">YouTube</label>
          <input
            name="name"
            type="text"
            v-model="user.youtube_name"
            :disabled="true" />
            <button v-if="user.youtube_name" @click="() => promptRemoveAccount('youtube')" class="button error">Remove</button>
            <button v-else @click="() => handleAddAccount('youtube')" class="button">Add</button>
        </div>
        <div v-if="removeAccountError" class="error margin-top-small">
          An error has occurred, please try again later.
        </div>
      </div>

      <h3 class="margin-bottom-small">Delete account</h3>
      <div class="settings-card">
        This will permanently delete your account and associated information.
        <div class="margin-top-small">
          <button
            @click="toggleConfirmDelete"
            :class="{disabled: loading}"
            class="button error">Delete account</button>
        </div>
      </div>

      <div :class="{'hidden': !shouldConfirm}" class="modal">
        <div class="screen" @click="toggleConfirmDelete" />
        <div class="modal-content">
          <h3>Are you sure you want to delete your account?</h3>
          <p>This will permanently delete your account and associated information. You will no longer be able to stream with TOONin or access any of your previous TOONin stream information. <span class="error">This cannot be undone.</span></p>

          <hr />

          <div class="column">
            <label for="deleteReason">Please tell us why you are leaving</label>
            <textarea name="deleteReason" rows="4" v-model="deleteReason" />
          </div>
          <div v-if="deleteError" class="error margin-top-small">
            An error has occurred, please try again later or contact us for help.
          </div>
          <div class="margin-top-small">
            <button
              @click="deleteAccount"
              class="button error margin-right-small">
              <div v-if="sending" class="loader" />
              <span>Delete account</span>
            </button>
            <button
              @click="toggleConfirmDelete"
              class="button">
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="{'hidden': !removeAccountProvider}">
      <div class="screen" @click="closePrompt" />
      <div class="modal-content" v-if="user.twitch_id && user.youtube_id">
        <h3>Are you sure you want to unsync your account with {{removeAccountProvider}}?</h3>
        <p>You will no longer be able to stream TOONin with your {{removeAccountProvider}} account.</p>
        <div class="modal-buttons">
          <button class="button margin-right-small" @click="() => removeAccount(removeAccountProvider)">Remove {{removeAccountProvider}}</button>
          <button class="button light" @click="closePrompt">Cancel</button>
        </div>
      </div>
      <div class="modal-content" v-else>
        <h3>You must have at least one streaming service connected to your account.</h3>
        <div class="modal-buttons">
          <button class="button" @click="closePrompt">OK</button>
        </div>
      </div>
    </div>

    <div class="modal" :class="{'hidden': !promptHybridAccount}">
      <div class="screen" @click="closePrompt" />
      <div class="modal-content">
        <h3>Do you want to add {{promptHybridAccount}} access to TOONin?</h3>
        <div class="modal-buttons">
          <button class="button margin-right-small" @click="() => login(promptHybridAccount)">Add account</button>
          <button class="button light" @click="closePrompt">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Settings',
  data() {
    return {
      user: {},
      loading: true,
      updated: false,
      sending: false,
      invalidFields: [],
      updateError: false,
      shouldConfirm: false,
      deleteError: false,
      deleteReason: null,
      removeAccountProvider: null,
      promptHybridAccount: null,
      removeAccountError: false,
    };
  },
  methods: {
    getUser() {
      return fetch('/user', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Charset': 'utf-8',
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.user = res;
        });
    },
    update() {
      this.resetForm();
      const { name, email, id } = this.user;
      if (!name || !email) {
        if (!name) {
          this.invalidFields.push('name');
        }

        if (!email) {
          this.invalidFields.push('email');
        }

        return Promise.resolve();
      }

      gtag('event', 'updateInfo');
      this.sending = true;
      return fetch('/user', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name, email, id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (Object.keys(res).length > 0) {
            this.user = res;
            this.resetForm();
            this.updated = true;
            return res;
          }

          return Promise.reject();
        })
        .catch(() => {
          this.resetForm();
          this.updateError = true;
        });
    },
    resetForm() {
      this.sending = false;
      this.updated = false;
      this.updateError = false;
      this.invalidFields = [];
    },
    toggleConfirmDelete() {
      gtag('event', 'toggleConfirmDelete', { value: !this.shouldConfirm });
      this.shouldConfirm = !this.shouldConfirm;
    },
    deleteAccount() {
      const { id } = this.user;
      gtag('event', 'deleteAccount', { id });
      return fetch('/user', {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, deleteReason: this.deleteReason }),
      })
        .then(() => {
          window.location = '/';
        })
        .catch(() => {
          this.resetForm();
          this.deleteError = true;
        });
    },
    handleAddAccount(provider) {
      this.promptHybridAccount = provider;
    },
    login(provider) {
      if (provider === 'twitch') {
        window.location = '/connect/twitch';
      } else if (provider === 'youtube') {
        window.location = '/connect/google';
      }
    },
    promptRemoveAccount(provider) {
      this.removeAccountProvider = provider;
    },
    removeAccount(provider) {
      const { id } = this.user;
      return fetch('/user/removeAccount', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id, provider,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (Object.keys(res).length > 0) {
            this.user = res;
            this.removeAccountProvider = null;
            this.removeAccountError = false;
            return res;
          }

          return Promise.reject();
        })
        .catch(() => {
          this.removeAccountProvider = null;
          this.removeAccountError = true;
        });
    },
    closePrompt() {
      this.removeAccountProvider = null;
      this.promptHybridAccount = null;
    },
  },
  mounted() {
    const self = this;
    this.getUser()
      .then(() => {
        self.loading = false;
      })
      .catch(() => {
        self.$router.back();
      });
  },
};
</script>

<style lang="scss" scoped>
  @import "../style/_variables.scss";

  .settings-card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-bottom: 2rem;
    background-color: $color-white;
    color: $color-text-light;
    border-radius: 1rem;
    max-width: 600px;
  }

  .settings-card_input {
    display: flex;
    align-items: center;

    label {
      flex: 1;
      padding-right: 1rem;
    }

    input {
      flex: 6;
    }
  }
</style>
