export default async function translate(text) {
  return fetch('/translate', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Charset': 'utf-8',
    },
    body: JSON.stringify({ text }),
  })
    .then((res) => res.json())
    .then((res) => res.translation)
    .catch((err) => {
      console.log('Translation error', err);
      return text;
    });
}
