<template>
  <nav id="nav">
    <div class="logo-left">
      <router-link to="/">
        <!-- <img src="../assets/mayor wang-icon.png" class="logo" alt="Mayor Wang Logo"> -->
        TOONin
      </router-link>
    </div>
    <!-- <div class="logo-container">
      <router-link to="/"><img src="../assets/mayor wang-icon.png" class="logo" alt="Mayor Wang Logo"></router-link>
      <span>Powered by HRE</span>
    </div> -->
    <div class="nav-items">
      <a
        v-if="user"
        href="/setup"
        @click="() => gtag('event', 'click', { btn: 'account' })"
        :class="{'active': this.activeLink === '/setup'}"
        class="nav-link">
        Stream
      </a>
      <a
        v-if="user"
        href="/history"
        @click="() => gtag('event', 'click', { btn: 'history' })"
        :class="{'active': this.activeLink === '/history'}"
        class="nav-link">
        History
      </a>
      <a
        v-if="user"
        @click="() => gtag('event', 'click', { btn: 'settings' })"
        href="/settings"
        :class="{'active': this.activeLink === '/settings'}"
        class="nav-link">
        Settings
      </a>
      <!-- <div v-else class="nav-link-account" /> -->
      <button v-if="user" @click="logout" class="button" id="logout">Log out</button>
      <button v-else @click="login()" class="button" id="login">Log in</button>
    </div>

    <Login />
  </nav>
</template>

<script>
import Login from '@/components/Login.vue';

export default {
  name: 'Nav',
  components: {
    Login,
  },
  data() {
    return {
      user: null,
      hideLogin: true,
      activeLink: '/',
    };
  },
  watch: {
    $route() {
      this.activeLink = this.$route.path;
    },
  },
  methods: {
    isActive(link) {
      const { pathname } = window.location;
      return pathname === link;
    },
    login() {
      gtag('event', 'click', { btn: 'login' });
      this.$router.push({ path: '/log-in' });
    },
    logout() {
      gtag('event', 'logout');
      fetch('/logout', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          window.location = '/';
        })
        .catch((err) => {
          console.log('logout err', err);
        });
    },
    getUser() {
      fetch('/user')
        .then((res) => res.json())
        .then((res) => {
          if (res && res.id) {
            this.user = res;
          }
        });
    },
  },
  mounted() {
    this.activeLink = this.$route.path;
  },
  beforeMount() {
    this.getUser();
  },
};
</script>

<style scoped lang="scss">
  @import "../style/_variables.scss";

  nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background-color: $color-background-light;
    // color: $color-text-light;
    padding: .5rem 2rem;
  }

  .logo-left {
    display: flex;
    font-size: 2rem;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    .logo {
      margin-right: 1rem;
      height: 2.5rem;
    }

    .logo-text {
      height: .7rem;
    }
  }

  // .logo-container {
  //   display: flex;
  //   align-items: center;
  //   height: calc(100% - 1rem);
  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   transform: translate(-50%, -50%);

  //   a, img {
  //     height: 100%;
  //   }
  // }

  // .spacer {
  //   padding: 0 .5rem;
  // }

  .nav-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }

  // .nav-link-account, .nav-link-settings {
  //   fill: $color-black;
  //   width: 2rem;
  //   height: 2rem;
  //   margin-right: 1rem;

  //   &:hover {
  //     fill: $color-primary-dark;
  //   }

  //   &.active {
  //     fill: $color-primary-dark;
  //   }
  // }

  .nav-link {
    background-color: transparent;
    border: none;
    color: $color-primary;
    padding: 0 .5rem;
    text-decoration: none;

    &:last-of-type {
      padding-right: 1rem;
    }

    &:hover {
      color: $color-primary-dark;
    }
  }

  @media (max-width: 1000px) {
    nav {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
</style>
