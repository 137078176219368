<template>
  <div class="stat">
    <h3 v-if="title" class="stat-title">{{title}}</h3>
    <h2 v-if="data || data === 0" class="stat-value">{{readableNumber(data)}}</h2>
  </div>
</template>

<script>

export default {
  name: 'Stat',
  props: ['title', 'data'],
  data() {
    return {
    };
  },
  methods: {
    readableNumber(num) {
      if (!isNaN(num)) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }

      return num;
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../../style/_variables.scss";

  .stat {
    display: inline-block;
    // flex-direction: column;
    text-align: center;
    background-color: #c5c5c5;
    color: $color-text-light;
    border-radius: 5px;
    margin-right: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .stat-value {
    margin-bottom: 0;
  }
</style>
