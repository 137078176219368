<template>
  <div class="line-chart" ref="chart" />
</template>

<script>

import * as d3 from 'd3';

export default {
  name: 'LineChart',
  props: ['chartData'],
  data() {
    return {
    };
  },
  methods: {
    createSVG() {
      /* eslint-disable */
      const data = this.chartData;
      const width = 1000;
      const height = 500;
      const margin = ({top: 20, right: 30, bottom: 30, left: 40});
      const x = d3.scaleTime()
        .domain(d3.extent(data, d => d.date))
        .range([margin.left, width - margin.right]);
      const y = d3.scaleLinear()
        .domain(d3.extent(data, d => d.sentiment && d.sentiment.compound)).nice()
        .range([height - margin.bottom, margin.top]);
      const color = d3.scaleSequential(y.domain(), d3.interpolateSpectral);
      const xAxis = g => g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0))
        // .call(g => g.select(".domain").remove());
      const yAxis = g => g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        // .call(g => g.select(".domain").remove())
        .call(g => g.select(".tick:last-of-type text").append("tspan").text(data.y));
      const line = d3.line()
        .curve(d3.curveStep)
        .defined(d => !isNaN(d.sentiment && d.sentiment.compound))
        .x(d => x(d.date))
        .y(d => y(d.sentiment && d.sentiment.compound));
      const svg = d3.create("svg")
        .attr("viewBox", [0, 0, width, height]);

      const gradient = {id: "O-2"};

      svg.append("g")
          .call(xAxis);

      svg.append("g")
          .call(yAxis);

      svg.append("linearGradient")
          .attr("id", gradient.id)
          .attr("gradientUnits", "userSpaceOnUse")
          .attr("x1", 0)
          .attr("y1", height - margin.bottom)
          .attr("x2", 0)
          .attr("y2", margin.top)
        .selectAll("stop")
          .data(d3.ticks(0, 1, 10))
        .join("stop")
          .attr("offset", d => d)
          .attr("stop-color", color.interpolator());

      svg.append("path")
        .datum(data.filter(line.defined()))
        .attr("stroke", "#ccc")
        .attr("fill", "none")
        .attr("d", line);

      svg.append("path")
          .datum(data)
          .attr("fill", "none")
          // .attr("stroke", gradient)
          .attr("stroke", `url(#${gradient.id})`)
          .attr("stroke-width", 1.5)
          .attr("stroke-linejoin", "round")
          .attr("stroke-linecap", "round")
          .attr("d", line);

      return svg.node();
      /* eslint-enable */
    },
  },
  mounted() {
    const svg = this.createSVG();
    this.$refs.chart.append(svg);
  },
  beforeDestroy() {
  },
};
</script>

<style scoped lang="scss">
</style>
